<template>
  <div class="trad-invoice page-container">
    <PageTitle title="傳統發票" />
    <p class="trad-invoice_content mb-9">
      上傳發票照片需清晰顯示，包含<br />「發票號碼」、「發票開立時間」、「Asahi產品與金額」，若資訊不足導致無法辨識則審核失敗
    </p>
    <!-- btn -->
    <BaseBtn
      text="輸入發票相關資訊"
      @click="routeTo('/tradInvoiceUpload')"
    />
    <BaseBtn
      text="重新登錄發票類型"
      type="back"
      :isOutlined="true"
      @click="routeTo('/invoiceLog')"
    />
    <BaseBtn
      text="回到首頁"
      type="back"
      :isOutlined="true"
      @click="routeTo('/home')"
    />
    <!-- Sample -->
    <div class="sample">
      <p class="sample_title">傳統發票範本如下</p>
      <p class="sample_p">若發票類型錯誤，將不具抽獎資格</p>
      <img class="sample_img" src="@/assets/img/sample-trad.png" />
    </div>
  </div>
</template>

<script>
import PageTitle from "@/components/PageTitle.vue";
import BaseBtn from "@/components/BaseBtn.vue";

export default {
  name: "TradInvoice",
  components: {
    PageTitle,
    BaseBtn,
  },
  data() {
    return {};
  },
  methods: {
    routeTo(route) {
      this.$router.push(route);
    },
  },
};
</script>
